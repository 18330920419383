import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import { Toast } from 'bootstrap'; // Import Bootstrap Toast component

const ToastComponent = ({ showMessage, message, toastID, styleSuffix }) => {
    useEffect(() => {
        if (showMessage) {
            const toastElement = document.getElementById(toastID);
            const toast = new Toast(toastElement, {
                delay: 20000, // 20 seconds delay
            });
            toast.show();
        }
    }, [showMessage, message, toastID, styleSuffix]); // Depend on showMessage and message to react

    return (
        <div
            id={toastID}
            className={"toast hide align-items-center text-white border-0 " + styleSuffix}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div className="d-flex">
                <div className="toast-body">
                    {message}
                </div>
                <button
                    type="button"
                    className="btn-close me-2 m-auto"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                ></button>
            </div>
        </div>
    );
};

export default ToastComponent;