import React, { useRef, useEffect } from 'react';

function IframeComponent({ htmlContent, frameDepth }) {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        // Get the document object of the iframe
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        // Write the passed HTML into the iframe
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();
    }, [htmlContent]); // Depend on htmlContent so it updates when content changes

    return (
        <iframe title="ifc" ref={iframeRef} width="100%" height={frameDepth} frameBorder="0" scrolling="no"></iframe>
    );
}

export default IframeComponent;
