import React, { useRef , useState } from 'react';
import IframeComponent from './IframeComponent';

const HtmlRenderTab = ({ manualAssistHTML, showHAI }) => {

    const [htmlCode, setHtmlCode] = useState(`<html><body></body></html>`);

    const handleGetHtml = async () => {
        try {
            setHtmlCode(manualAssistHTML);
        } catch (error) {
            console.error('Error fetching HTML:', error);
        }
    };

    return (
        <div>
            {showHAI ? (
                <div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary btn-sm m-2 float-end" onClick={handleGetHtml}>Display HAI</button>
                    </div>
                    <div>
                        <IframeComponent htmlContent={htmlCode} frameDepth="3000px" />
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default HtmlRenderTab;