import './App.css';

// App.js
import React, { useState, useEffect } from 'react';

import FileUploadTab from './FileUploadTab';
import HtmlRenderTab from './HtmlRenderTab';
import TableViewTab from './TableViewTab';
import ArchitecturalDataTab from './ArchitecturalDataTab';

import axios from 'axios';

import ToastComponent  from './ToastComponent';

function App() {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const [hiddenFileAsBase64String, setHiddenFileAsBase64String] = useState('');

    const [file, setFile] = useState(null);
    const [surveyID, setSurveyID] = useState(null);
    const [arbitrarySurveyID, setArbitrarySurveyID] = useState('');
    const [image, setImage] = useState(null);

    const [surveyStatus, setSurveyStatus] = useState(false);
    const [manualAssistHTML, setManualAssistHTML] = useState('');
    const [interactiveViewerHTML, setInteractiveViewerHTML] = useState('');
    const [surveyMessage, setSurveyMessage] = useState('');
    const [finalResult, setFinalResult] = useState([]);
    const [imgAsbase64, setImgAsbase64] = useState('');
    const [googleCoordinates, setGoogleCoordinates] = useState('');

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [styleSuffix, setStyleSuffix] = useState('');

    const [showUpload, setShowUpload] = useState(false);
    const [showHAI, setShowHAI] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const [accuracy, setAccuracy] = useState([]);

    const handleShowToast = (message, timeout, styleSuffix) => {
        setToastMessage(message);
        setShowToast(true);
        setStyleSuffix(styleSuffix);
        setTimeout(() => setShowToast(false), timeout);
    };

    const handleFileChange = (e) => {

        const pdfFile = e.target.files[0]; // Get the selected file
        setFile(pdfFile);

        const reader = new FileReader(); // Create a new FileReader instance

        // Define a callback function to be executed when file reading is completed
        reader.onload = (e) => {
            const base64String = e.target.result.split(',')[1]; // Extract base64 string
            console.log('Base64 version of PDF is :', base64String);

            setHiddenFileAsBase64String(base64String);
        };

        // Read the file as Data URL (base64 encoded)
        reader.readAsDataURL(pdfFile);

    };

    const getAccuracy = async () => {

        try {
            axios.get('https://9d00s9v654.execute-api.eu-west-1.amazonaws.com/dev/accuracy?id=' + surveyID, {
                headers: {
                    'x-api-key': 'SAfUsJubu82xI5jXNRq5D16ExRC47a1Z67O17420'
                }
            })
                .then(response => {
                    // debugger;
                    setAccuracy(response?.data?.accuracy_advice);
            })
            .catch(error => {
                handleShowToast('Failed to get accuracy advice', 3000, " bg-danger");
            });
        } catch (error) {
            console.error('Error fetching accuracy advice:', error);
        }
    };

    const getEmbeddableViewer = async () => {

        try {
            axios.get('https://9d00s9v654.execute-api.eu-west-1.amazonaws.com/dev/interactive-viewer', {
                headers: {
                    'x-api-key': 'SAfUsJubu82xI5jXNRq5D16ExRC47a1Z67O17420'
                }
            })
                .then(response => {
                    // debugger;
                    setInteractiveViewerHTML(response?.data);
                    handleShowToast('Viewer retrieved.', 3000, " bg-success");
            })
            .catch(error => {
                handleShowToast('Failed to get viewer', 3000, " bg-danger");
            });
        } catch (error) {
            console.error('Error fetching viewer:', error);
        }
    };

    const getManualAssistHTML = async () => {

        try {
            axios.get('https://9d00s9v654.execute-api.eu-west-1.amazonaws.com/dev/manual-assist?id=' + surveyID, {
                headers: {
                    'x-api-key': 'SAfUsJubu82xI5jXNRq5D16ExRC47a1Z67O17420'
                }
            })
                .then(response => {
                    // debugger;
                    setManualAssistHTML(response?.data);
                    handleShowToast('Manual assist retrieved.', 3000, " bg-success");
            })
            .catch(error => {
                handleShowToast('Failed to get manual assist tool', 3000, " bg-danger");
            });
        } catch (error) {
            console.error('Error fetching manual assist tool:', error);
        }
    };

    const loadSurveyById = async () => {
        if (arbitrarySurveyID) {
            await setSurveyID(arbitrarySurveyID);

            setImgAsbase64('');
            setGoogleCoordinates('');
            setFinalResult([]);
            setShowResults(false);
        }
    };

    const updateSurveyStatus = async () => {

        try {
            axios.get('https://9d00s9v654.execute-api.eu-west-1.amazonaws.com/dev/survey?id=' + surveyID, {
                headers: {
                    'x-api-key': 'SAfUsJubu82xI5jXNRq5D16ExRC47a1Z67O17420',
                }
            })
            .then(response => {
                setSurveyStatus(response?.data?.survey_status);
                const current_status = response?.data?.survey_status;

                if (current_status === "OCR_SUCCESSFULLY_COMPLETED") {
                    getManualAssistHTML();
                    setShowHAI(true);
                }

                if ((current_status === "COMPLETED") && (response?.data?.basemap_extraction_results)) {

                    setImgAsbase64(response?.data?.result_image);

                    let results = [];

                    if (response?.data?.basemap_extraction_results)
                        results = response?.data?.basemap_extraction_results;

                    if (response?.data?.google_data)
                        setGoogleCoordinates(response?.data?.google_data);

                    getEmbeddableViewer();
                    setFinalResult(results);
                    setShowResults(true);
                    
                }

                handleShowToast('Survey status update: ' + current_status, 3000, " bg-success");
            })
            .catch(error => {
                handleShowToast('Unable to update survey status', 3000, " bg-danger");
            });
        } catch (error) {
            console.error('Error fetching updated status:', error);
        }
    };

    const handleNewSurveyValue = (event) => {
        setArbitrarySurveyID(event.target.value);
    };

    const handleUpload = async () => {
        if (!file) {
            console.log("No file ... returning early!");
            return;
        }

        try {
            const data = {
                'survey_filename': 'base64_survey_file',
                'base64_survey_file': hiddenFileAsBase64String
            };

            const uploadResponse = await axios.post(
                "https://9d00s9v654.execute-api.eu-west-1.amazonaws.com/dev/survey",
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'SAfUsJubu82xI5jXNRq5D16ExRC47a1Z67O17420',
                    }
                }
            );

            if (uploadResponse?.data?.id) {
                setSurveyID(uploadResponse?.data?.id);
                setSurveyStatus(uploadResponse?.data?.survey_status);
                setShowUpload(true);

                handleShowToast('Survey submitted, ID:' + uploadResponse?.data?.id, 3000, " bg-success");
            } else {
                handleShowToast('Survey submitted, no ID detected!', 3000, " bg-danger");
            }
           
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div className="container-fluid">
            <div>
                <a href="https://www.isprynt.com">
                    <img className="rounded float-end" alt="Developed by iSprynt Nigeria Limited" src="https://www.isprynt.com/sprynt-google-logo4.png"></img>
                </a>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="p-3">
                        <div className="mb-3">
                            <label htmlFor="fileInput" className="form-label">Choose Survey PDF File:</label>
                            <input type="file" className="form-control" id="fileInput" onChange={handleFileChange}></input>
                        </div>
                        <button className="btn btn-primary btn-sm" disabled={surveyID} onClick={handleUpload}>Upload File</button>
                        <button className="btn btn-primary btn-sm m-2" onClick={updateSurveyStatus}>Update Status</button>
                    </div>
                    <div className="m-3">
                        {showToast && <ToastComponent
                            showMessage={showToast}
                            message={toastMessage}
                            toastID={'toast' + Math.floor(Math.random() * 1000)}
                            styleSuffix={styleSuffix}
                        />}
                    </div>
                    <div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Survey ID</th>
                                    <td><p className="fs-6">{surveyID}</p></td>
                                </tr>
                                <tr>
                                    <th scope="row">Status</th>
                                    <td><p className="fs-6">{surveyStatus}</p></td>
                                </tr>
                                <tr>
                                    <th scope="row">Message</th>
                                    <td>{surveyMessage}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    {showHAI ? (
                        <div className="py-3">
                            <div><button className="btn btn-primary btn-sm m-2" onClick={getAccuracy}>Check Points Accuracy</button></div>
                            <div className="m-2">Accuracy advice:<br></br>{JSON.stringify(accuracy)}</div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {/*
                    <div className="py-3">
                        <div className="py-2">Load any survey (by ID)</div>
                        <div className="row g-2">
                            <div className="col-auto">
                                <label htmlFor="loadSurveyById" className="visually-hidden">Survey ID</label>
                                <input type="text" onChange={handleNewSurveyValue} className="form-control" id="loadSurveyById" placeholder="Survey ID"/>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-primary btn-sm" onClick={loadSurveyById}>Load Survey</button>
                            </div>
                        </div>
                    </div>
                    */}
                </div>
                <div className="col-md-9">
                    <div className="p-3">
                        <div className="panel panel-default">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} href="#tab1" data-bs-toggle="tab" onClick={() => handleTabClick('tab1')} >Uploaded Survey</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} href="#tab2" data-bs-toggle="tab" onClick={() => handleTabClick('tab2')}>Architectural Data</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} href="#tab3" data-bs-toggle="tab" onClick={() => handleTabClick('tab3')}>Human Assist</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} href="#tab4" data-bs-toggle="tab" onClick={() => handleTabClick('tab4')}>Results</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane fade m-4 ${activeTab === 'tab1' ? 'show active' : ''}`} id="tab1">
                                    <FileUploadTab base64String={hiddenFileAsBase64String} showUpload={showUpload} />
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'tab2' ? 'show active' : ''}`} id="tab2">
                                    <ArchitecturalDataTab manualAssistHTML={manualAssistHTML} showHAI={showHAI} />
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'tab3' ? 'show active' : ''}`} id="tab3">
                                    <HtmlRenderTab manualAssistHTML={manualAssistHTML} showHAI={showHAI} />
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'tab4' ? 'show active' : ''}`} id="tab4">
                                    <TableViewTab googleCoordinates={googleCoordinates}  finalResult={finalResult} imgAsbase64={imgAsbase64} showResults={showResults} interactiveViewerHTML={interactiveViewerHTML} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
