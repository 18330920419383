import React, { useRef, useState } from 'react';

const ArchitecturalDataTab = () => {
    const [activeDataTab, setActiveDataTab] = useState('propertyDataTab');

    const handleSubmitData = async () => {
        try {
        } catch (error) {
            console.error('Error submitting architectural data:', error);
        }
    };

    const handleDataTabClick = (tabId) => {
        setActiveDataTab(tabId);
    };

    return (
        <div className="">
            <div className="d-flex justify-content-end">
                <button className="btn btn-primary btn-sm m-2 float-end" onClick={handleSubmitData}>Submit Architectural Data</button>
            </div>
            <div className="bg-light p-3">
                <form className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label htmlFor="ref_no" className="form-label">Applicant Reference No</label>
                        <input type="text" className="form-control" id="ref_no" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="applicant_name" className="form-label">Applicant Name</label>
                            <input type="text" className="form-control" id="applicant_name" />
                    </div>
                </form>
            </div>

            <div>

                <div className="container mt-3">
                    <ul className="nav nav-underline" id="dataTabs" role="tablist">
                        <li className="nav-item">
                            <a className={`nav-link ${activeDataTab === 'propertyDataTab' ? 'active' : ''}`} id="property-details-tab" href="#propertyDataTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('propertyDataTab')} role="tab" aria-controls="Property Data" aria-selected="true">Property Details</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeDataTab === 'buildingDataTab' ? 'active' : ''}`} id="building-details-tab" href="#buildingDataTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('buildingDataTab')} role="tab" aria-controls="Building Data" aria-selected="true">Building Details</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeDataTab === 'facilitiesDataTab' ? 'active' : ''}`} id="facilities-tab" href="#facilitiesDataTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('facilitiesDataTab')} role="tab" aria-controls="Facilities Data" aria-selected="true">Facilities and Amenities</a>
                        </li>
                    </ul>
                    <div className="tab-content p-3">
                        <div className={`tab-pane fade m-4 ${activeDataTab === 'propertyDataTab' ? 'show active' : ''}`} id="propertyDataTab" role="tabpanel">
                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="title" className="col-sm-4 col-form-label">Title Type</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="title" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="proposed_use" className="col-sm-4 col-form-label">Proposed Use of Development</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="proposed_use" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="app_type" className="col-sm-4 col-form-label">Special Application Type</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="app_type" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="location" className="col-sm-4 col-form-label">Location Of Development Town</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="location" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="dev_class" className="col-sm-4 col-form-label">Development Class</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="dev_class" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="prop_loc" className="col-sm-4 col-form-label">Property Location</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="prop_loc" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="District" className="col-sm-4 col-form-label">District</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="District" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="plot_size" className="col-sm-4 col-form-label">Size of Plot (sqm)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="plot_size" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="floor_dim" className="col-sm-4 col-form-label">Floor Dimensions (LxWxH)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="floor_dim" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="plot_Area" className="col-sm-4 col-form-label">% of Developed Area Plot Size</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="plot_Area" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="landscape" className="col-sm-4 col-form-label">% of Landscape</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="landscape" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="undevArea" className="col-sm-4 col-form-label">% of Undeveloped Area</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="undevArea" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="main_area" className="col-sm-4 col-form-label">Area of Main Building</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="main_area" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="letable_Area" className="col-sm-4 col-form-label">Length of Lettable Area</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="letable_Area" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="breathe" className="col-sm-4 col-form-label">Breadth of Lettable Area</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="breathe" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={`tab-pane fade m-4 ${activeDataTab === 'buildingDataTab' ? 'show active' : ''}`} id="buildingDataTab" role="tabpanel">
                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="building_type" className="col-sm-4 col-form-label">Building Type</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="building_type" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="units" className="col-sm-4 col-form-label">No. of Units</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="units" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="parking" className="col-sm-4 col-form-label">No. of Parking Space</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="parking" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="parking_cat" className="col-sm-4 col-form-label">Category of Parking Space</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="parking_cat" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="air_space" className="col-sm-4 col-form-label">Air Spaces Right Side (m)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="air_space" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="rear_air_space" className="col-sm-4 col-form-label">Air Space Rear Setback (m)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="rear_air_space" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="left_air_space" className="col-sm-4 col-form-label">Air Space Left Side (m)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="left_air_space" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="between" className="col-sm-4 col-form-label">Between Building (m)</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="between" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="road_setback" className="col-sm-4 col-form-label">Set Back from Road</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="road_setback" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="set_back_line" className="col-sm-4 col-form-label">Set Back from Property Boundary Line</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="set_back_line" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={`tab-pane fade m-4 ${activeDataTab === 'facilitiesDataTab' ? 'show active' : ''}`} id="facilitiesDataTab" role="tabpanel">
                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="toilets" className="col-sm-4 col-form-label">Convenience No. of Toilets</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="toilets" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="baths" className="col-sm-4 col-form-label">Convenience No. of Bathrooms</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="baths" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="kitchens" className="col-sm-4 col-form-label">Convenience No. of Kitchens</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="kitchens" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="min_size" className="col-sm-4 col-form-label">Minimum Size of Convenience</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="min_size" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="beds" className="col-sm-4 col-form-label">No. of Bedrooms</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="beds" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="sitting" className="col-sm-4 col-form-label">No. of Sitting Rooms</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="sitting" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="escape" className="col-sm-4 col-form-label">Presence of Escape Exit/Door/Staircase</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="escape" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="anc" className="col-sm-4 col-form-label">Presence of Ancillary Buildings</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="anc" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="water" className="col-sm-4 col-form-label">Source of Water Supply</label>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" id="water" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArchitecturalDataTab;
