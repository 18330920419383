import React, { useCallback, useState } from 'react';
import IframeComponent from './IframeComponent';
import proj4 from 'proj4';
import { GoogleMap, useLoadScript, MarkerF, PolygonF } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '800px'
};

const TableViewTab = ({ googleCoordinates, finalResult, imgAsbase64, interactiveViewerHTML, showResults }) => {

    // showResults = true;

    const [image, setImage] = useState(true);
    const [mapData, setMapData] = useState(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCY3FmP8PHeKjz_dXWZzPSRx0eO61bQ9aA"
    });

    const [htmlCode, setHtmlCode] = useState(`<html><body></body></html>`);

    const [activeDataTab, setActiveDataTab] = useState('staticBaseMapTab');

    const handleDataTabClick = (tabId) => {
        setActiveDataTab(tabId);
    };

    const handleGetTableData = async () => {
        try {
            setHtmlCode(interactiveViewerHTML);
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    const convertCoordinatePair = (x, y) => {
        const utm31n = '+proj=utm +zone=31N +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
        const wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
        return proj4(utm31n, wgs84, [parseFloat(x), parseFloat(y)]);
    }

    const renderMap = useCallback(() => {
        if (!mapData) return null;

        return (
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapData.center}
                zoom={17}
            >
                <MarkerF position={mapData.center} />
                <PolygonF
                    paths={mapData.polygonPath}
                    options={{
                        fillColor: "lightblue",
                        fillOpacity: 0.4,
                        strokeColor: "blue",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        geodesic: false,
                        zIndex: 1
                    }}
                />
            </GoogleMap>
        );
    }, [mapData]);

    const convertCoordinatesForGoogleMaps = () => {
        const data = JSON.parse(googleCoordinates);
        // const data = JSON.parse("{\"x_centroid\": 538948.7487757165, \"y_centroid\": 727340.43, \"polygon_coordinates\": [[538992.8995353522, 727285.9803071899],[538927.6673681511, 727259.558559287],[538907.5363297435, 727308.683801196],[538910.4758170112, 727317.6860406176],[538971.8556350846, 727341.6170316812]]}");
        // const data = JSON.parse("{\"x_centroid\": 539364.118339334, \"y_centroid\": 726422.8036425462, \"polygon_coordinates\": [[539368.153, 726456.152], [539389.6159781668, 726401.2910059495], [539360.6291719007, 726389.3237730826], [539338.2972213348, 726443.9556720421], [539368.1658064007, 726456.1448158248]]}");
        console.log("Google data:");
        console.log(data);

        const fudge_factor_x = -0.00074;
        const fudge_factor_y = 0.00104;

        let google_data = {};

        let [lon, lat] = [null, null];

        if (data?.x_centroid && data?.y_centroid) {
            [lon, lat] = convertCoordinatePair(data?.x_centroid, data?.y_centroid);
            google_data.center = { "lat": lat + fudge_factor_y, "lng": lon + fudge_factor_x };
        }

        let polygon_path = [];

        if (data?.polygon_coordinates) {
            for (let i = 0; i < data.polygon_coordinates.length; i++) {
                [lon, lat] = convertCoordinatePair(data.polygon_coordinates[i][0], data.polygon_coordinates[i][1]);
                polygon_path.push({ "lat": lat + fudge_factor_y, "lng": lon + fudge_factor_x })
            }
        }

        google_data.polygonPath = polygon_path;
        setMapData(google_data)
        console.log('google_data:');
        console.log(google_data);
    };

    return (
        <div>
            {showResults ? (
                <div>
                    <div className="row pt-5">
                        <div className="shaded-results" style={{}} >
                            <pre>{JSON.stringify(finalResult, null, 2)}</pre>
                        </div>
                    </div>
                    <div className="container mt-3">
                        <ul className="nav nav-underline" id="dataTabs" role="tablist">
                            <li className="nav-item">
                                <a className={`nav-link ${activeDataTab === 'staticBaseMapTab' ? 'active' : ''}`} id="static-base-map-tab" href="#staticBaseMapTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('staticBaseMapTab')} role="tab" aria-controls="Static Base Map Extract" aria-selected="true">Static Base Map Extract</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeDataTab === 'interactiveGoogleMapTab' ? 'active' : ''}`} id="interactive-google-tab" href="#interactiveGoogleMapTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('interactiveGoogleMapTab')} role="tab" aria-controls="Interactive Google Map" aria-selected="true">Interactive Google Map</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeDataTab === 'interactiveBaseMapTab' ? 'active' : ''}`} id="interactive-base-tab" href="#interactiveBaseMapTab" data-bs-toggle="tab" onClick={() => handleDataTabClick('interactiveBaseMapTab')} role="tab" aria-controls="Interactive Base Map" aria-selected="true">Interactive Base Map</a>
                            </li>
                        </ul>
                        <div className="tab-content p-3">
                            <div className={`tab-pane fade m-4 ${activeDataTab === 'staticBaseMapTab' ? 'show active' : ''}`} id="staticBaseMapTab" role="tabpanel">
                                <div className="center-map">
                                    {image && <img src={`data:image/png;base64,${imgAsbase64}`} alt="Uploaded" />}
                                </div>
                            </div>
                            <div className={`tab-pane fade m-4 ${activeDataTab === 'interactiveGoogleMapTab' ? 'show active' : ''}`} id="interactiveGoogleMapTab" role="tabpanel">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary btn-sm m-2 float-end" onClick={convertCoordinatesForGoogleMaps}>Show Google Map</button>
                                </div>
                                <div className="row">
                                    <div className="center-map">
                                        {renderMap()}
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade m-4 ${activeDataTab === 'interactiveBaseMapTab' ? 'show active' : ''}`} id="interactiveBaseMapTab" role="tabpanel">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary btn-sm m-2 float-end" onClick={handleGetTableData}>Show Interactive Base Map</button>
                                </div>
                                <div className="row p-3">
                                    <div className="col-md-12">
                                        <IframeComponent htmlContent={htmlCode} frameDepth="900px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>

    );
};

export default TableViewTab;
