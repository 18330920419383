// FileUploadTab.js
import React, { useState } from 'react';

const FileUploadTab = ({ base64String, showUpload }) => {
    const src = `data:application/pdf;base64,${base64String}`;
    return (
        <div className="container">
          <div className="row">
                <div className="col-md-12">
                    {showUpload ? (
                        <object
                            data={src}
                            type="application/pdf"
                            width="100%"
                            height="2000px"
                            aria-label="PDF Viewer">
                            <p>Your browser does not support PDFs.
                                <a href={src}>Download the PDF</a>.</p>
                        </object>

                    ) : (
                            <div className="alert alert-primary" role="alert">
                                Please select a survey file to upload using the <strong>Choose File</strong>  button on the top right
                            </div>
                    )}
                </div>
            </div>
          </div>
    );
};

export default FileUploadTab;